const RAPPORTS_URL = `/admin/rapport`;
const RAPPORT_CODE_URL = `/admin/set-rapport-code`;

export default {
  state: {
    rapports: [],
  },
  getters: {
    getTypeRapports(state) {
      return state.rapports;
    },
  },
  mutations: {
    setRapports(state, data) {
      state.rapports = data;
    },
  },
  actions: {
    async fetchRapports({ getters, commit }, payload) {
      try {
        return await getters.authenticatedAxios
          .get(RAPPORTS_URL, {
            params: {
              ...payload,
            },
          })
          .then((response) => commit("setRapports", response.data.data));
      } catch (e) {
        return e;
      }
    },
    async updateRapport({ getters }, rapport) {
      try {
        return await getters.authenticatedAxios.put(
          `${RAPPORTS_URL}/${rapport.id}`,
          rapport
        );
      } catch (e) {
        throw e;
      }
    },
    async setCodeForAdherant({ getters }, { rapport, adherent, new_code }) {
      try {
        const url = `${RAPPORT_CODE_URL}/${rapport.id}/${adherent.id}`;
        return await getters.authenticatedAxios.post(url, {code: new_code});
      } catch (e) {
        return e;
      }
    },
  },
};
