const ADHERENTS_URL = `/admin/user`;
const ADHERENTS_IDS_URL = `/admin/user_ids`;
const DELETED_USERS_URL = `/admin/deleted-users`;
const RESTORE_USER_URL = `/admin/restore-user`;
const DEFINITIVE_USER_DELETE_URL = `/admin/definitive-user-delete`;
const ADHERENTS_CONFIRMATION_URL = `/admin/user/change-etat`;
const ADHERENTS_SOUMISSION_URL = `/admin/user/validate-pre-inscription`;
const DOSSIERS_REQUIS_DELETE_URL = `/admin/user/upload-documents`;
const UPDATE_PWD_URL = `/admin/user/reset-password`;
const PROFILE_PIC_URL = `/user/upload-profile-picture`;
const CHECK_MATRICULE_URL = `/user/check-matricule`;

export default {
    state: {
        adherents: [],
        list_ids: [],
        deleted_users: [],
        selected_adherent: {},
        unfiltered_adherents: [],
        all_unfiltered_adherents_emails_ids: [],
        new_users_count: null,
        selected_etat_value : undefined,
        selected_secteur_value : undefined,
        selected_wilaya_value : undefined,
        selected_search_value : "",
        selected_paiement_value : undefined,
        selected_etat_professionnel_value : undefined,
        selected_exercice_value : undefined,
        etatsAdherents: [
            {key: 0, value: "Non soumis"},
            {key: 1, value: "En attente"},
            {key: 2, value: "Accepté"},
            {key: 3, value: "Refusé"},
        ],
        default_pagination: {
            current: 1,
            total: 0,
            pageSize: 10,
        },
        pagination: {
            current: 1,
            total: 0,
            pageSize: 10,
        },
    },
    getters: {
        getSelectedAdherentListOfListDossier(state) {
            return state.selected_adherent
                ? state.selected_adherent.list_dossier
                : "";
        },
        getSelectedAdherentListOfProfilePhoto(state) {
            if (state.selected_adherent && state.selected_adherent.profile_picture) {
                return [
                    {
                        uid: "-1",
                        name: state.selected_adherent.profile_picture,
                        status: "done",
                        url: state.selected_adherent.profile_picture_url,
                    },
                ];
            }
            return [];
        },
        getSelectedAdherent(state) {
            return state.selected_adherent;
        },
        getSelectedAdherentEtatActivites(state) {
            return state.selected_adherent
                ? state.selected_adherent.etat_activites
                : [];
        },
        getSelectedUserEtatActivites(state) {
            return state.selected_adherent
                ? state.selected_adherent.etat_activites
                : [];
        },
        getAdherentEnAttenteCount(state) {
            return state.new_users_count;
        },
        getAdherentById(state) {
            return (id) => {
                return state.adherents.find((adherent) => adherent.id === id);
            };
        },
        getAdherentByEmail(state) {
            return (email) => {
                return state.adherents.find((adherent) => adherent.email === email);
            };
        },
        getPagination(state) {
            return {
                per_page: state.pagination.pageSize,
                page: state.pagination.current,
            };
        },
        getSelectedEtatValue(state){
            return state.selected_etat_value 
        },
        getSelectedPaiementValue(state){
            return  state.selected_paiement_value 
        },
        getSelectedSecteurValue(state){
            return  state.selected_secteur_value 
        },
        getSelectedWilayaValue(state){
            return  state.selected_wilaya_value 
        },
        getSelectedSearchValue(state){
            return  state.selected_search_value 
        },
        getSelectedEtatProfessionnelValue(state){
            return  state.selected_etat_professionnel_value 
        },
        getSelectedExerciceValue(state){
            return  state.selected_exercice_value 
        },
        getIds(state){
            return state.list_ids;
        },
    },
    mutations: {
        setAdherents(state, data) {
            state.adherents = data;
        },
        setIds(state, data) {
            state.list_ids = data;
        },
        setDeletedUsers(state, data) {
            state.deleted_users = data;
        },
        setPagination(state, data) {
            state.pagination = data;
        },
        setDefaultPagination(state) {
            state.pagination = state.default_pagination;
        },
        setPaginationFromServer(state, data) {
            state.pagination = {
                pageSize: Number(data.per_page),
                current: Number(data.current_page),
                total: Number(data.total),
            };
        },
        setSelectedAdherent(state, data) {
            state.selected_adherent = data;
        },
        setSelectedAdherentListDossier(state, data) {
            state.selected_adherent.list_dossier = data.list_dossier;
        },
        setNewUsersCount(state, count) {
            state.new_users_count = count;
        },
        setClearSelectedAdherentProfilePhoto(state) {
            state.selected_adherent.photoFileDownloadUri = null;
            state.selected_adherent.photoFileName = null;
            state.selected_adherent.photoFileSize = 0;
            state.selected_adherent.photoFileType = null;
        },
        clearSelectedAdherent(state) {
            state.selected_adherent = null;
        },
        setSelectedEtatValue(state, data){
            state.selected_etat_value  = data
        },
        setSelectedPaiementValue(state, data){
            state.selected_paiement_value  = data
        },
        setSelectedSecteurValue(state, data){
            state.selected_secteur_value  = data
        },
        setSelectedWilayaValue(state, data){
            state.selected_wilaya_value  = data
        },
        setSelectedSearchValue(state, data){
            state.selected_search_value  = data
        },
        setSelectedEtatProfessionnelValue(state, data){
            state.selected_etat_professionnel_value  = data
        },
        setSelectedExerciceValue(state, data){
            state.selected_exercice_value  = data
        },
    },
    actions: {
        async clearAdherentProfilePic({dispatch, getters}, id) {
            try {
                return await getters.authenticatedAxios
                    .delete(`${PROFILE_PIC_URL}/${id}`)
                    .then(() => {
                        dispatch("fetchAdminAdherentsById", id);
                    });
            } catch (e) {
                throw e;
            }
        },
        async storeAdminAdherent({dispatch, getters}, adherent) {
            try {
                return await getters.authenticatedAxios
                    .post(ADHERENTS_URL, adherent)
                    .then((response) => {
                        dispatch("fetchAdminAdherentsById", response.data.id);
                    });
            } catch (e) {
                throw e;
            }
        },
        async fetchAdminAdherents({dispatch, commit, getters}, payload) {
            try {
                let para = {
                    ...payload,
                    ...getters.getPagination,
                };
                return await dispatch(
                    "fetchAdminAdherentsWithoutSettingState",
                    para
                ).then((response) => {
                    commit("setAdherents", response.data.data);
                    commit("setPaginationFromServer", response.data.meta);
                });
            } catch (e) {
                return e;
            }
        },
        async fetchAdminAdherentsIds({commit, getters}, payload) {
            try {
                return await getters.authenticatedAxios.get(`${ADHERENTS_IDS_URL}`, {
                    params: {
                        ...payload,
                    },
                }).then((response) => {
                    commit("setIds", response.data.data);
                });
            } catch (e) {
                return e;
            }
        },
        async fetchAdminAdherentsWithoutSettingState({getters}, payload) {
            try {
                return await getters.authenticatedAxios.get(`${ADHERENTS_URL}`, {
                    params: {
                        ...payload,
                    },
                });
            } catch (e) {
                throw e;
            }
        },
        async restoreUser({getters}, payload) {
            try {
                return await getters.authenticatedAxios.put(`${RESTORE_USER_URL}/${payload}` );
            } catch (e) {
                throw e;
            }
        },
        async fetchDeletedusers({getters, commit}) {
            try {
                return await getters.authenticatedAxios.get(`${DELETED_USERS_URL}`)
                    .then((response) => commit('setDeletedUsers', response.data.data));
            } catch (e) {
                throw e;
            }
        },
        async fetchAdminAdherentsById({dispatch, commit}, id) {
            try {
                return await dispatch("fetchOnlyAdminAdherentsById", id).then(
                    (response) => {
                        commit("setSelectedAdherent", response.data);
                    }
                );
            } catch (e) {
                throw e;
            }
        },
        async fetchOnlyAdminAdherentsById({getters}, id) {
            try {
                return await getters.authenticatedAxios.get(`${ADHERENTS_URL}/${id}`);
            } catch (e) {
                throw e;
            }
        },
        async fetchAdminAdherentsListDossierById({dispatch, commit}, id) {
            try {
                return await dispatch("fetchOnlyAdminAdherentsById", id).then(
                    (response) => {
                        commit("setSelectedAdherentListDossier", response.data);
                    }
                );
            } catch (e) {
                throw e;
            }
        },
        async fetchNewَAdherents({dispatch, commit}) {
            try {
                return await dispatch("enqueueToCounter", {
                    key: "new_users_count",
                    resolver: (data) => {
                      commit("setNewUsersCount", data);
                    },
                  });
            } catch (e) {
                return e;
            }
        },
        async updateAdminAdherent({commit, getters}, adherent) {
            try {
                return await getters.authenticatedAxios
                    .put(`${ADHERENTS_URL}/${adherent.id}`, adherent)
                    .then((response) => {
                        commit("setSelectedAdherent", response.data);
                    });
            } catch (e) {
                throw e;
            }
        },
        async updateAdherentPassword({getters}, payload) {
            try {
                return await getters.authenticatedAxios.post(
                    `${UPDATE_PWD_URL}`,
                    payload
                );
            } catch (e) {
                throw e;
            }
        },
        async updateAdherentConfirmation({getters}, data) {
            try {
                return await getters.authenticatedAxios.put(
                    `${ADHERENTS_CONFIRMATION_URL}/${data.id}`,
                    data
                );
            } catch (e) {
                throw e;
            }
        },
        async updateAdherentsSoumission({getters, dispatch}, adherent) {
            try {
                return await getters.authenticatedAxios
                    .put(`${ADHERENTS_SOUMISSION_URL}/${adherent.id}`)
                    .then(() => dispatch("fetchAdminAdherentsById", adherent.id));
            } catch (e) {
                throw e;
            }
        },
        async destroyAdherentDossierRequis({getters}, payload) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${DOSSIERS_REQUIS_DELETE_URL}/${payload.user_id}/${payload.file_id}`
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyAdminAdherent({getters}, id) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${ADHERENTS_URL}/${id}`
                );
            } catch (e) {
                throw e;
            }
        },
        async destroyUserDefinitely({getters}, id) {
            try {
                return await getters.authenticatedAxios.delete(
                    `${DEFINITIVE_USER_DELETE_URL}/${id}`
                );
            } catch (e) {
                throw e;
            }
        },
        async checkMatriculeExistance({getters}, params) {
            try {
                return await getters.authenticatedAxios.post(
                    `${CHECK_MATRICULE_URL}`,
                    params
                );
            } catch (e) {
                throw e;
            }
        },
    },
};
