const DOSSIERS_REQUIS_URL = `/admin/dossier-requi`;
const DOSSIERS_REQUIS_FOR_REGISTERING_URL = `/dossier-requis-for-register`
const DELETE_DOSSIERS_REQUIS_BY_AHDERANT_URL = `/user/upload-documents`;

export default {
  state: {
    dossiers_requis: [],
  },
  getters: {
    getDossiersRequis(state) {
      return state.dossiers_requis;
    },
  },
  mutations: {
    setDossiersRequis(state, data) {
      state.dossiers_requis = data.sort((a, b) => {
        return !a.id - b.id;
      });
    },
    filteredListOfDossiersRequis(state, keyword) {
      state.dossiers_requis = state.dossiers_requis.filter((dossier_requi) => {
        return dossier_requi.nom_fr
          ? dossier_requi.nom_fr.toLowerCase().includes(keyword.toLowerCase())
          : false;
      });
    },
  },
  actions: {
    async fetchDossiersRequis({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(DOSSIERS_REQUIS_URL)
          .then((response) => commit("setDossiersRequis", response.data.data));
      } catch (e) {
        throw e;
      }
    },
    async fetchDossiersRequisForRegistering({ getters, commit }) {
      try {
        return await getters.authenticatedAxios
          .get(DOSSIERS_REQUIS_FOR_REGISTERING_URL)
          .then((response) => commit("setDossiersRequis", response.data.data));
      } catch (e) {
        throw e;
      }
    },
    async updateDossierRequi({ getters }, document) {
      try {
        return await getters.authenticatedAxios.put(
          `${DOSSIERS_REQUIS_URL}/${document.id}`,
          document
        );
      } catch (e) {
        throw e;
      }
    },
    async storeDossierRequi({ getters }, document) {
      try {
        return await getters.authenticatedAxios.post(
          `${DOSSIERS_REQUIS_URL}`,
          document
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyDossierRequi({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.delete(
          `${DOSSIERS_REQUIS_URL}/${payload.id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async destroyDossierRequiByAdherant({ getters }, payload) {
      try {
        return await getters.authenticatedAxios.delete(
          `${DELETE_DOSSIERS_REQUIS_BY_AHDERANT_URL}/${payload.doc_id}`
        );
      } catch (e) {
        throw e;
      }
    },
    async filteredListOfDossiersRequis({ dispatch, commit }, keyword) {
      try {
        if (keyword === "") {
          return dispatch("fetchDossiersRequis").then(() =>
            commit("filteredListOfDossiersRequis", keyword)
          );
        }
        commit("filteredListOfDossiersRequis", keyword);
      } catch (e) {
        throw e;
      }
    },
  },
};
