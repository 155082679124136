const DEMANDE_URL = `/demande`;
const DEMANDE_OUVERTURES_UPDAPTE_ETAT = `/admin/demande/update-etat`;
import _findIndex from "lodash-es/findIndex";


export default {
    state: {
        demandes: [],
        etats: [
            {key: 1, 'value': 'En attente'},
            {key: 2, 'value': 'En traitement'},
            {key: 3, 'value': 'Accepté'},
            {key: 4, 'value': 'Refusée'},
        ],
        demandes_ouvertures_count: 0,
        default_pagination: {
            current: 1,
            total: 0,
            pageSize: 10,
        },
        pagination: {
            current: 1,
            total: 0,
            pageSize: 10,
        },
    },
    getters: {
        getDemandes(state) {
            return state.demandes;
        },
        getTypeDemandeName(state) {
            return  function(type){
                let index = _findIndex(state.type_demandes, ['key', type]);
                return state.type_demandes[index]['value'];
            } ;
        },
        getDemandesOuverturesCount(state) {
            return state.demandes_ouvertures_count;
        }
    },
    mutations: {
        setDemandes(state, data) {
            state.demandes = data;
        },
        setDemandeOuverturesCount(state, count) {
            state.demandes_ouvertures_count = count;
        },
        setDemandesPagination(state, data) {
            state.pagination = data;
        },
        setDemandesDefaultPagination(state) {
            state.pagination = state.default_pagination;
        },
        setDemandesPaginationFromServer(state, data) {
            state.pagination = {
                pageSize: Number(data.per_page),
                current: Number(data.current_page),
                total: Number(data.total),
            };
        },
    },
    actions: {
        async fetchDemandes({commit, dispatch}, payload) {
            try {
                return dispatch(
                    "fetchDemandesWithoutSettingState", payload
                ).then((response) =>{
                    commit("setDemandes", response.data.data);
                    commit("setDemandesPaginationFromServer", response.data.meta);
                });
            } catch (e) {
                return e;
            }
        },
        async fetchDemandesByAdherent({commit, dispatch}, payload) {
            try {
                return dispatch(
                    "fetchDemandesWithoutSettingState", payload
                ).then((response) =>{
                    commit("setDemandes", response.data.data);
                });
            } catch (e) {
                return e;
            }
        },
        async fetchNewDemandeOuverturesCount({dispatch, commit}) {
            try {
                return await dispatch("enqueueToCounter", {
                    key: "new_demande_count",
                    resolver: (data) => {
                      commit("setDemandeOuverturesCount", data);
                    },
                  });
            } catch (e) {
                return e;
            }
        },
        async storeDemandes({getters}, payload) {
            try {
                return await getters.authenticatedAxios.post(
                    `${DEMANDE_URL}`,
                    payload
                );
            } catch (e) {
                return e;
            }
        },
        async fetchDemandeById({getters, commit}, id) {
            try {
                return await getters.authenticatedAxios
                    .get(`${DEMANDE_URL}/${id}`);
            } catch (e) {
                return e;
            }
        },
        async destroyDemande({getters, commit}, id) {
            try {
                return await getters.authenticatedAxios
                    .delete(`${DEMANDE_URL}/${id}`);
            } catch (e) {
                return e;
            }
        },
        async updateDemande({getters, commit}, payload) {
            try {
                return await getters.authenticatedAxios
                    .put(`${DEMANDE_URL}/${payload.id}`, payload);
            } catch (e) {
                return e;
            }
        },
        async updateEtatDemande({getters, commit}, payload) {
            try {
                return await getters.authenticatedAxios
                    .put(`${DEMANDE_OUVERTURES_UPDAPTE_ETAT}/${payload.id}`, payload);
            } catch (e) {
                return e;
            }
        },
        async fetchDemandesWithoutSettingState({getters}, payload) {
            try {
                return await getters.authenticatedAxios.get(DEMANDE_URL, {
                    params: {
                        ...payload,
                    },
                });
            } catch (e) {
                return e;
            }
        },
    },
};